import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  state: {
    version: 20210210,
    token: null,
    valorTotalFonteRecurso: 0,
    user: null,
    profile: null,
    redirect: null
  },
  getters: {
    profile: state => {
      return state.profile || {};
    },
    redirect: state => {
      return state.redirect || "/projects";
    }
  },
  mutations: {
    setLoginUser(state, message) {
      state.user = message;
      if (!message) state.profile = {};
    },
    setToken(state, token) {
      state.token = token;
      if (!token) state.profile = {};
    },
    setProfile(state, message) {
      state.profile = message;
    },
    setRedirect(state, message) {
      state.redirect = message;
    }
  }
});
