import Vue from "vue";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./plugins/vuetify-mask.js";
import VueRamda from "vue-ramda";
import firebase from "firebase/app";
import "firebase/auth";

import axios from "axios";
import store from "./store";

const firebaseConfig = {
  apiKey: "AIzaSyCepjGtQ46SeI5xCQTExQiXTDuH-iELbWk",
  authDomain: "calculadora-cultural.firebaseapp.com",
  projectId: "calculadora-cultural",
  storageBucket: "calculadora-cultural.appspot.com",
  messagingSenderId: "675335593999",
  appId: "1:675335593999:web:557e6d1398b642f76c437a",
};
import moment from "moment";

firebase.initializeApp(firebaseConfig);

Vue.use(VueRamda);

Vue.use(VueCookies);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === "development";

firebase.auth().onAuthStateChanged(function(user) {
  // debugger;
  if (user) {
    user.getIdToken(true).then(function(idToken) {
      if (idToken) this.$cookies.set("token", idToken, "1h");
      // console.log(data)
    });
  }
});

firebase.getCurrentUser = () => {
  // debugger;
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

Vue.use({
  install(Vue) {
    Vue.prototype.$moment = moment;
    Vue.prototype.$api = function() {
      const _vm = this;
      return new Promise(function(resolve) {
        firebase
          .getCurrentUser()
          .then((user) => {
            user.getIdToken(true).then((idToken) => {
              // debugger;
              var call = axios.create({
                baseURL: location.hostname.toString().includes("calculadora") ? "https://api.calculadoracultural.com.br" : "http://localhost:3000",
                headers: {
                  common: {
                    it: idToken,
                  },
                },
              });
              resolve(call);
            });
          })
          .catch(() => {
            var redirPath = (!location.pathname.includes("login") && location.pathname) || null;
            _vm.$store.commit("setRedirect", redirPath);
            router.push("/login");
          });
      });
    };
  },
});

new Vue({
  vuetify,
  store,
  render: (h) => h(App),
  router,
}).$mount("#app");
