<template>
  <v-container>
    <template v-if="!isChrome">
      <v-container fluid="fluid">
        <v-alert type="error" dismissible="dismissible">
          Este navegador não é suportado. Por favor, utilize o Google Chrome, Microsoft Edge ou outro compatível.
        </v-alert>
      </v-container>
    </template>
    <template v-else>
      <v-container fluid="fluid">
        <section id="firebaseui-auth-container"></section>
      </v-container>
    </template>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

import * as firebaseui from "firebaseui";

import router from "../router";

export default {
  data: () => ({
    jsonData: {},
    intervalDisableFB: null,
  }),
  computed: {
    currentUser() {
      console.log("getCurrentUser");
      return this.$store.state.user;
    },
    isChrome() {
      const userAgent = navigator.userAgent;
      const isChrome = /chrome|chromium/i.test(userAgent);
      return isChrome;
    },
  },
  mounted() {
    this.jsonData = { o: "d0", ic: this.isChrome };
    this.intervalDisableFB = setInterval(() => {
      if (document.querySelector(".firebaseui-idp-facebook")) {
        console.log("CLRFB");
        document.querySelector(".firebaseui-idp-facebook").setAttribute("disabled", "disabled");
        document.querySelector(".firebaseui-idp-facebook").style.display = "none";
        clearInterval(this.intervalDisableFB);
      }
    }, 100);

    // var ui = new firebaseui.auth.AuthUI(firebase.auth());
    let ui = firebaseui.auth.AuthUI.getInstance();
    const _vm = this;

    console.log("mounted");
    let currentUser = firebase.auth().currentUser;

    if (currentUser && currentUser.getIdToken) {
      console.log({ currentUser });
      this.jsonData.ae0 = 1;
      currentUser.getIdToken(false).then((idToken) => {
        this.jsonData.inn = idToken;
        this.jsonData.ep = "EP0";
        console.log(JSON.stringify(this.jsonData, null, 4));
      });
    } else {
      console.log("no current user");
      this.jsonData.ep = "NOP";
    }

    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
      this.jsonData.ui = "INI";
    }
    var uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          this.jsonData.ep = "SCB";
          firebase
            .auth()
            .currentUser.getIdToken(false)
            .then((idToken) => {
              this.$cookies.set("token", idToken, "3h");

              localStorage.idToken = idToken;
              localStorage.user = JSON.stringify({
                name: authResult.user.displayName,
                image: authResult.user.photoURL,
              });

              let userData = {
                idToken,
                user: {
                  name: authResult.user.displayName,
                  image: authResult.user.photoURL,
                },
              };

              this.$store.commit("setToken", idToken);
              this.$store.commit("setProfile", userData);

              var redirectTo = (_vm.$store.state.redirect && !_vm.$store.state.redirect.includes("login") && _vm.$store.state.redirect) || null;
              // alert("redirect to: " + redirectTo);
              if (redirectTo) {
                router.push({ path: _vm.$store.state.redirect });
              } else {
                router.push({ name: "Projects" });
              }
            })
            .catch((e) => {
              this.jsonData.catch = "err: " + e.message;
              alert("falha ao tentar autenticar - tente novamente mais tarde");
            });
        },
      },
      // signInSuccessUrl: '/projetos',
      signInOptions: [firebase.auth.FacebookAuthProvider.PROVIDER_ID, firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    };
    ui.start("#firebaseui-auth-container", uiConfig);
  },
  methods: {
    teste: function() {
      firebase
        .auth()
        .currentUser.getIdToken(false)
        .then((idToken) => {
          this.jsonData = { idToken, ls: localStorage.idToken, id: 1 };
        })
        .catch(() => {
          this.jsonData = { idToken: "falha ao tentar autenticar - tente novamente mais tarde" };
        });
    },
  },
};
</script>
