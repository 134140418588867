import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/es5/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#573688",
        secondary: "#e53d31"
      }
    }
  },
  lang: {
    locales: { pt },
    current: "pt"
  }
});
