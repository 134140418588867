import Vue from "vue";
import VueRouter from "vue-router";
// import axios from "axios";
import Login from "../components/Login.vue";
import firebase from "firebase/app";
import store from "../store";

Vue.use(VueRouter);

var router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/form/novo",
      name: "Form",
      component: () => import("../components/archive/Form.vue"),
    },
    {
      path: "/projects",
      name: "Projects",
      component: () => import("../pages/projects/index.vue"),
    },
    {
      path: "/projects/:id",
      name: "EditProject",
      component: () => import("../pages/projects/edit.vue"),
    },
    {
      path: "/projects/dna/:id",
      name: "ProjectDna",
      component: () => import("../pages/projects/dna.vue"),
    },
    {
      path: "/projects/report/:id",
      beforeEnter: (to) => {
        firebase.getCurrentUser().then((user) => {
          user.getIdToken(true).then((token) => {
            // debugger;
            const baseUrl = location.hostname.toString().includes("calculadora") ? "https://api.calculadoracultural.com.br" : "http://localhost:3000";

            console.log("beforeEnder", to.params.id, token);
            window.location.assign(baseUrl + "/project/" + to.params.id + "/report" + "?it=" + token);
          });
        });
      },
    },
    {
      path: "*",
      redirect: "/projects",
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  var user = await firebase.getCurrentUser();
  if (!user && to.name.toLowerCase() !== "login") {
    next({ path: "/login" });
  } else if (!user) {
    if (to.name.toLowerCase() !== "login") {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    user.getIdToken(true).then((token) => {
      if ((!user && to.name.toLowerCase() !== "login") || !token) {
        next({ path: "/login" });
      } else {
        if (user) {
          let userData = {
            user: {
              name: user.displayName,
              image: user.photoURL,
            },
          };

          store.commit("setProfile", userData);
          store.commit("setToken", token);
        }

        next();
      }
    });
  }
});

export default router;
